'use client';
import '../../output.css';
import { ReactNode } from 'react';
import Image from 'next/image';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { usePathname } from 'next/navigation';
import Anchor from '@shared-ui/components/Anchor';


export default function ClientLayout({ children }: { children: ReactNode }) {

  const queryClient = new QueryClient();
  // const pathname = usePathname();
  // const isLoggedInSetup = pathname.startsWith('/app/setup');
  // const isLogin = pathname.startsWith('/login');

  return (
    <QueryClientProvider client={queryClient}>
      <div className="h-screen p-2 bg-neutral-900">
        <div className="relative h-full px-5 pt-16 overflow-hidden bg-white rounded-xl">
          <div className="relative z-20 w-full h-full max-w-6xl mx-auto overflow-scroll">
            <Anchor href="/">
              <Image width={132} height={28} src="/img/logo-black.svg" alt="OkGrow logo" className="mx-auto duration-300 hover:scale-95 animate-all" loading='eager' priority={true} />
            </Anchor>
            <div className="max-w-sm px-3 py-10 mx-auto xl:py-14">{children}</div>
          </div>

          <div className="absolute top-0 left-0 w-screen h-full opacity-80">
            <Image src="/img/shapes/shapes.svg" fill alt="Background Shapes" role='presentation' className='opacity-0 pointer-events-none animate-fade-in' />
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
}
